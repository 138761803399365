import React, { useContext } from "react";
import Prismic from "@prismicio/client";
import { Client } from "@/services/prismic";
import { NextSeo } from "next-seo";
import { Box } from "@chakra-ui/react";
import { DashboardContainer, PreviewContainer } from "../components/containers/Homepage/Containers";
import { UserContext } from "../contexts/userContext";

const Homepage = ({ dashboardData, previewData, welcomeMessage }) => {
    const { userState } = useContext(UserContext);

    const dashboardContent = dashboardData.results[0].data;
    const previewContent = previewData.results[0].data;

    return (
        <React.Fragment>
            {/* TODO: Make this more scalable to adhere to Prismic SEO metadata structure, and provide backup logic if
            the content is not available in Prismic */}
            <NextSeo
                title={previewContent.meta_title}
                description={previewContent.meta_description}
                canonical="https://members.vacayou.com"
                openGraph={{
                    url: "https://members.vacayou.com",
                    title: previewContent.meta_title,
                    description: previewContent.meta_description,
                    images: [
                        {
                            url: "/images/opengraph/vacayou-voyager-club-og-image.jpg",
                            width: 1200,
                            height: 630,
                            alt: "Vacayou Voyager Club",
                        },
                    ],
                    site_name: "Vacayou Voyager Club",
                }}
            />
            <Box>
                {userState.userType === "VOYAGER" ? (
                    <DashboardContainer content={dashboardContent} welcomeMessage={welcomeMessage} />
                ) : (
                    <PreviewContainer content={previewContent} />
                )}
            </Box>
        </React.Fragment>
    );
};

export async function getStaticProps() {
    // Fetch dashboard modules
    const dashboardData = await Client().query(Prismic.Predicates.at("document.type", "dashboard"));

    // Fetch preview modules
    const previewData = await Client().query(Prismic.Predicates.at("document.type", "homepage"));

    // Fetch new member welcome message module
    const welcomeMessage = await Client().query(Prismic.Predicates.at("document.type", "welcome_quiz"));

    return {
        props: {
            dashboardData,
            previewData,
            welcomeMessage,
        },
        revalidate: 300,
    };
}

export default Homepage;
